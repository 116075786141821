.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-family: 'TT Norms Pro', sans-serif;
  display: inline-block;
  color: var(--s-btn-color);
  cursor: pointer;
  padding: 4px 17px;
  appearance: none;
  border-radius: 1000px;
  border: 2px solid var(--s-btn-border);
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
@media (max-width: 1023px) {
  .button {
    font-size: 16px;
    line-height: 1.5625;
  }
}
@media (max-width: 767px) {
  .button {
    font-size: 11px;
    line-height: 2.27272727;
    padding: 0 10px;
  }
}
.donateButton {
  background-color: #ADC008;
  color: #FFFFFF;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.013em;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  transform: rotate(-10deg);
  padding: 13px 13px 30px;
  position: relative;
  font-weight: bold;
}
.donateButton:after {
  content: '';
  position: absolute;
  bottom: 23px;
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-heart-white.svg);
}
@media (max-width: 1023px) {
  .donateButton {
    font-size: 15px;
    line-height: 1.13333333;
    padding: 12px 12px 20px;
  }
}
@media (max-width: 767px) {
  .donateButton {
    font-size: 11px;
    line-height: 1.18181818;
    padding: 8px 8px 20px;
  }
  .donateButton:after {
    width: 11px;
    height: 11px;
    bottom: 20px;
  }
}
#home {
  height: 50px;
}
.section--header {
  padding: 25px 0;
}
.section--multimood {
  height: 312px;
}
.section--footer {
  padding: 30px 0;
}
.footpart--one {
  grid-column: 1 / span 6;
}
.footpart--two {
  grid-column: 10 / span 3;
  margin-top: 0;
}
.vcard {
  width: 100%;
}
.toplink {
  width: 29px;
  height: 29px;
}
.footservices {
  margin-top: 10px;
}
.area--one {
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitOne {
  grid-column: span 12;
}
.area--one .unitOne .partWidth--1-4 {
  grid-column: span 3;
}
.area--one .unitTwo {
  grid-column: span 12;
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitTwo--imageLeft .unit__content {
  grid-column: 7 / span 6;
  margin-left: -110px;
}
.area--one .unitTwo--imageLeft .unit__background {
  grid-column: 1 / span 6;
  margin-left: 0;
}
.area--one .unitTwo--imageRight .unit__content {
  grid-column: 1 / span 6;
}
.area--one .unitTwo--imageRight .unit__background {
  grid-column: 7 / span 6;
  margin-right: 0;
}
.area--one .unitTwo .unit__content {
  width: calc(100% + 110px);
  margin-top: 100px;
  padding: 12px 35px;
}
.area--one .unitTwo .unit__background {
  width: 100%;
}
.area--one .unitTwo .part.pict:first-child {
  width: 65px;
}
.area--one .unitTwo .part.text:nth-child(2) {
  width: calc(100% - 110px);
}
.area--one .unitThree {
  grid-column: span 6;
}
.area--one .unitThree .unit__background {
  height: 160px;
}
.area--one .unitThree .part.pict:first-child {
  height: 160px;
}
.area--one .unitFour {
  padding: 40px 0;
  grid-column: span 12;
}
.area--one .unitFour .more,
.area--one .unitFour .less {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.area--one .unitFour .fold-toggle:before {
  width: 27px;
  height: 27px;
}
#view .area--one .unitFour .fold-toggle {
  width: 31px;
  height: 31px;
}
.area--one .unitFour .partWidth--1-1 {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}
.area--one .unitFour .partWidth--1-2 {
  width: calc(50% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}
.area--one .unitFour .partWidth--1-4 {
  width: calc(25% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}
.area--one .unitFive {
  padding: 0 50px 20px 50px;
  grid-column: span 12;
}
.area--one .unitFive .less .part.pict:first-child {
  width: calc(100% + 100px);
  margin-left: -50px;
  height: 175px;
}
.area--one .unitFive .fold-toggle:before {
  width: 31px;
  height: 31px;
}
#view .area--one .unitFive .fold-toggle {
  width: 35px;
  height: 35px;
}
.area--one .unitSix {
  grid-column: span 6;
}
.area--one .unitSix .unit__background {
  height: 160px;
}
.area--one .unitSix .part.pict:first-child {
  height: 160px;
}
.area--one .unitSix .part.pict:first-child .cb-image-caption {
  padding: 14px;
}
.area--one .unit--form {
  grid-column: span 12;
}
.part--donateButton {
  width: 124px;
  height: 124px;
}
.part--footTitle {
  margin-bottom: 20px;
}
.part--pageTitleIcon {
  width: 50px;
  height: 50px;
}
/*# sourceMappingURL=./screen-medium.css.map */